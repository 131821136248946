<template>
  <v-select
    dense
    outlined
    hide-details
    ref="select"
    item-text="text"
    item-value="value"
    class="cr-flow-select"
    :menu-props="{
      'offset-y': true,
      closeOnClick: true,
      nudgeLeft,
      maxWidth: menuMaxWidth
    }"
    :items="items"
    :label="label"
    :disabled="disabled"
    v-model="_value"
    @click:append.stop="$refs.select.activateMenu()"
  >
    <template v-slot:item="{ item }">
      <div :title="item.text" class="d-flex flex-grow-1">
        {{ item.text }}
      </div>
    </template>
  </v-select>
</template>

<style lang="scss" scoped>
.v-input.v-select::v-deep {
  .v-input__slot .v-select__slot {
    .v-select__selections {
      .v-select__selection {
        font-size: 0.82rem !important;
      }
      input {
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    value: {
      type: [Number, String, Object],
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    nudgeLeft: {
      type: Number,
      default: 0
    },
    menuMaxWidth: {
      type: Number,
      default: 400
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  }
};
</script>
