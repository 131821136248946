var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{ref:"select",staticClass:"cr-flow-select",attrs:{"dense":"","outlined":"","hide-details":"","item-text":"text","item-value":"value","menu-props":{
    'offset-y': true,
    closeOnClick: true,
    nudgeLeft: _vm.nudgeLeft,
    maxWidth: _vm.menuMaxWidth
  },"items":_vm.items,"label":_vm.label,"disabled":_vm.disabled},on:{"click:append":function($event){$event.stopPropagation();return _vm.$refs.select.activateMenu()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-grow-1",attrs:{"title":item.text}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}})}
var staticRenderFns = []

export { render, staticRenderFns }