<template>
  <div class="d-flex align-center mt-2 justify-space-between">
    <div class="d-flex align-center">
      <v-icon class="mb-1">mdi-circle-small</v-icon>
      프로젝트 멤버 기본 권한

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon size="16" v-on="on">mdi-help-circle-outline</v-icon>
        </template>

        <span>프로젝트에 초대되거나 참여 신청하는 멤버의 기본 권한입니다.</span>
      </v-tooltip>
    </div>

    <CrSelect :items="items" :value.sync="_defaultMemberAuthority" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .cr-flow-select.v-input.v-select {
    max-width: 120px;
    .v-input__slot {
      min-height: 34px;
      .v-select__slot .v-select__selections input {
        display: none;
      }
      .v-input__append-inner {
        margin-top: 6px;
      }
    }
  }
}
</style>

<script>
import CrSelect from "@/flow/views/components/commons/select";

export default {
  components: { CrSelect },
  props: {
    defaultMemberAuthority: {
      type: String,
      default: "READ"
    }
  },
  computed: {
    items() {
      return [
        { text: "게스트", value: "READ" },
        { text: "편집자", value: "EDIT" },
        { text: "운영자", value: "ADMIN" }
      ];
    },
    _defaultMemberAuthority: {
      get() {
        return this.defaultMemberAuthority;
      },
      set(authority) {
        this.$emit("update:defaultMemberAuthority", authority);
      }
    }
  }
};
</script>
